import React from "react";
import "../../Styles/animated.css";
export default function AnimatedBg() {
  return (
    <>
      <div div="true" className="bg"></div>
      <div div="true" className="bg bg2"></div>
      <div div="true" className="bg bg3"></div>
    </>
  );
}
