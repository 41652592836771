import React from "react";
import "../Styles/About.css";
import FadeInOut from "./misc/scrollInOut";
export default function About() {
  return (
    <section id="about">
      <div className="about-container">
        <FadeInOut>
          <h2 className="about-title">Om Meg</h2>
          <div className="pitch">
            <p>
              Jeg er for øyeblikket intern hos et spennende start-up selskap
              kalt CanEat, der jeg fungerer som Front-end utvikler, Designer og
              brukertester. Hovedsakelig fokuserer jeg på WordPress, UI og UX,
              samt brukertesting av appen.
            </p>
            <p>
              Min interesse for webutvikling har vært en lidenskap siden jeg på
              egenhånd lærte meg å mestre CSS og bbcoder. Personlig er jeg
              særlig opptatt av det visuelle aspektet, og jeg søker alltid å
              tilby de beste løsningene i samarbeid med mine oppdragsgivere,
              både når det gjelder estetikk og funksjonalitet.
            </p>
            <p>
              Jeg utvikler hele tiden mine visuelle ferdigheter gjennom
              fotografering, redigering og andre visuele medier. Noe som gir meg
              en unik tilnærming til designarbeidet mitt. Jeg inviterer deg til
              å utforske resten av porteføljen min for å få en dypere forståelse
              av de verktøyene jeg behersker og hva jeg kan tilby.
            </p>
            <p>
              Hvis du finner noe som fanger din interesse, nøl ikke med å ta
              kontakt med meg. Jeg er alltid ivrig etter å bidra med mine
              ferdigheter og ekspertise til å skape gode resultater for deg. Som
              en hyggelig, løsningsorientert, kreativ og pliktoppfyllende
              front-end utvikler, ser jeg frem til muligheten til å samarbeide
              med deg.
            </p>
          </div>
        </FadeInOut>
        <FadeInOut>
          <div className="tag-container">
            <h3 className="tag-title">Verktøy</h3>
            <div className="tag-content">
              <div className="tag tag-tool">#Visual Studio Code</div>
              <div className="tag tag-tool">#Adobe PhotoShop</div>
              <div className="tag tag-tool">#Adobe Illustrator</div>
              <div className="tag tag-tool">#Figma</div>
              <div className="tag tag-tool">#Google</div>
            </div>

            <h3 className="tag-title">Ferdigheter</h3>
            <div className="tag-content">
              <div className="tag tag-skill">#HTML5</div>
              <div className="tag tag-skill">#CSS</div>
              <div className="tag tag-skill">#SCSS</div>
              <div className="tag tag-skill">#Javascript</div>
              <div className="tag tag-skill">#UI/UX</div>
              <div className="tag tag-skill">#React</div>
              <div className="tag tag-skill">#Design</div>
              <div className="tag tag-skill">#GitHub</div>
              <div className="tag tag-skill">#Bootstrap</div>
              <div className="tag tag-skill">#Rest-Api</div>
            </div>

            <h3 className="tag-title">Utforsker</h3>
            <div className="tag-content">
              <div className="tag tag-learning">#Tailwind</div>
              <div className="tag tag-learning">#Daisy-UI</div>
              <div className="tag tag-learning">#MongoDB</div>
              <div className="tag tag-learning">#Node.js/Express</div>
              <div className="tag tag-learning">#Typescript</div>
              <div className="tag tag-learning">#.NET/C-Sharp</div>
            </div>
            <h3 className="tag-title">Interesser</h3>
            <div className="tag-content">
              <div className="tag tag-hobbies">#VideoSpill</div>
              <div className="tag tag-hobbies">#Tegning</div>
              <div className="tag tag-hobbies">#Fotografering</div>
            </div>
          </div>
        </FadeInOut>
      </div>
    </section>
  );
}
